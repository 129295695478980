<template>
  <div class="text-general row">
    <div class="col-md-4" />
    <div class="col-md-4">
      <Transition name="slide-up">
        <form v-if="authenticated === undefined">
          <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>

          <div
            class="alert alert-danger"
            v-if="error !== undefined"
            role="alert"
          >
            {{ error }}
          </div>

          <label for="inputUsername" class="sr-only">Username</label>
          <input
            type="text"
            v-model="username"
            id="inputUsername"
            class="form-control"
            placeholder="Username"
            required
            autofocus
          />

          <label for="inputPassword" class="sr-only">Password</label>
          <input
            type="password"
            v-model="password"
            id="inputPassword"
            class="form-control"
            placeholder="Password"
            required
          />

          <button v-on:click="login" class="btn btn-lg btn-primary btn-block">
            Log in
          </button>
        </form>
        <form v-else-if="authenticated !== undefined">
          <h1 class="h3 mb-3 font-weight-normal">Hi, {{ authenticated }}!</h1>
          <button v-on:click="logout" class="btn btn-lg btn-primary btn-block">
            Log out
          </button>
        </form>
      </Transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      authenticated: this.apis.auth.getUsername(),
      error: undefined,
    };
  },
  methods: {
    login(event) {
      let self = this;
      event.preventDefault();
      console.log("Perform login " + this.username + ":" + this.password);
      this.apis.auth.login(this.username, this.password).then((result) => {
        if (!result) {
          self.error = "Wrong username or password";
        } else {
          self.username = "";
          self.authenticated = this.apis.auth.getUsername();
        }
        self.password = "";
      });
    },
    logout(event) {
      event.preventDefault();
      console.log("Perform logout");
      this.apis.auth.logout();
      this.authenticated = undefined;
    },
  },
  mounted() {
    this.error = undefined;
  },
};
</script>

<style scoped>
form {
  position: absolute;
  width: 100%;
  max-width: 300px;
  margin: auto;
}

form .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
}

form .form-control:focus {
  z-index: 2;
}

form input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

form input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
